import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import BlogList from "../../components/BlogList/BlogList"
import Layout from "../../components/Layout/Layout"
import PageTitle from "../../components/PageTitle/PageTitle"
import useSiteMetadata from "../../components/SiteMetadata"


export const BlogIndex = ({data}) => {
  const {siteUrl} = useSiteMetadata()
  const description = (
    "Kingston Labs Blog: read about the technologies we are using to deliver " +
    "effective bespoke websites"
  )
  return (
    <Layout>
      <Helmet titleTemplate="%s | Kingston Labs">
        <title>Blog</title>
        <meta name="description" content={description}/>
        <meta property="og:title" content="Kingston Labs Blog"/>
        <meta property="og:description" content={description}/>
        <meta property="og:url" content={`${siteUrl}/blog/`}/>
      </Helmet>
      <PageTitle>Blog</PageTitle>
      <BlogList posts={data.allMarkdownRemark.edges}/>
    </Layout>
  )
}


BlogIndex.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  count: PropTypes.integer
}

export default BlogIndex


export const pageQuery = graphql`query BlogListQuery($deployDate: Date!) {
  allMarkdownRemark(
    sort: {order: DESC, fields: [frontmatter___date]}
    filter: {frontmatter: {templateKey: {eq: "blog-post"}, date: {lte: $deployDate}, visible: {eq: true}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          templateKey
          date(formatString: "MMMM DD, YYYY")
          summary
          listingImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}`
